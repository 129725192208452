<template>
  <div class="container">
    <el-button @click="test">test</el-button>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;

        // 监听脚本加载完成事件
        script.onload = () => {
          resolve();
        };

        // 监听脚本加载错误事件
        script.onerror = () => {
          reject(new Error(`Failed to load script: ${src}`));
        };

        document.body.appendChild(script);
      });
    },

    async test() {
      const scriptSrc =
        "http://127.0.0.1:5500/src/components/homes/test/pcell_test.js"; // 脚本文件路径
      this.loadScript(scriptSrc)
        .then(() => {
          // 脚本加载完成后，在这里可以访问脚本中的函数或变量
          // 进行相应的操作
          loadKernel(); // 脚本中定义的函数的调用
          setTimeout(() => {
            // let p = new window.Kernel.GdsPolygon(
            //   [
            //     [0, 0],
            //     [1, 1],
            //     [0, 1],
            //   ],
            //   0,
            //   1
            // )
          }, 1000);
        })
        .catch((error) => {
          // 脚本加载失败时的处理
          console.error(error);
        });
    },
  },
  watch: {},
};
</script>
<style lang="scss"></style>
